import React from 'react';
import PropTypes from "prop-types";
import useWindowSize from "../../../Utils/UseWindowSize";
import {Switch} from "@mui/material";
import {DataGrid, GridCellEditStopReasons} from "@mui/x-data-grid";
import useUtilsAdConfig from "../../../Controller/AdConfig/useUtilsAdConfig";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import {useSnackbar} from "notistack";
import useUtilsScreenConfig from "../../../Controller/ScreenConfig/useUtilsScreenConfig";
import useAssetsUtils from "../../../Controller/AppAssets/useAssetsUtils";


function AppAssetsTable(props) {
    AppAssetsTable.propTypes = {
        data: PropTypes.object.isRequired,
        onRefresh: PropTypes.func.isRequired,
    };
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();


    const {loading, update, deleteConfig} = useAssetsUtils();
    const columns = [
        {field: 'type', headerName: 'Type', width: (useWindowSize()[0] * 10) / 100, editable: false},
        {field: 'link', headerName: 'Link', width: (useWindowSize()[0] * 60) / 100, editable: true},
        {
            field: 'action', headerName: 'Action', width: (useWindowSize()[0] * 15) / 100,
            renderCell: (params) => {
                return <div style={{width: '100%', display: 'flex', justifyContent: 'space-evenly'}}>
                    <Button variant="outlined" startIcon={<DeleteIcon/>} color='error' onClick={() => {
                        deleteConfig({
                                id: params.id,
                                refresh: () => {
                                    props.onRefresh();
                                },
                            }
                        )
                    }
                    }>
                        Delete
                    </Button>
                </div>;
            },
        },

    ];


    return (
        <div style={{height: useWindowSize()[1] * 0.75, width: '100%'}}>
            <DataGrid
                loading={loading}
                rows={
                    props.data.map((e) => {
                        return {
                            id: e.id,
                            type: e.type,
                            link: e.link,
                            action: e,
                        }
                    })
                }
                columns={columns}
                onCellEditCommit={params => {
                    update({
                        id: params.id,
                        filedValue: params.value,
                        filedName: params.field,
                        refresh: () => {
                            props.onRefresh();
                        }
                    })
                }}
                pageSize={useWindowSize()[1] < 1000 ? 10 : 20}
                rowsPerPageOptions={[5]}
                onCellEditStop={(params, event) => {
                    // console.log(event);
                    // console.log(params);
                    if (params.reason === GridCellEditStopReasons.cellFocusOut) {
                        event.defaultMuiPrevented = true;
                    }
                }}
            />
        </div>
    );
}

export default AppAssetsTable;