import {createTheme} from '@mui/material/styles';


// All the following keys are optional.
// We try our best to provide a great default value.
const theme = createTheme({
    palette: {

        primary: {
            main: '#788bff',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#788bff',
        },
        typography: {
            allVariants: {
                fontFamily: 'Inter',
                textTransform: 'none',
                fontSize: 16,
            },

        },
        // Used by `getContrastText()` to maximize the contrast between the background and
        // the text.
        contrastThreshold: 3,
        // Used to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
});

export default theme;