import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, Typography} from "@mui/material";
import useWindowSize from "../../../Utils/UseWindowSize";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import PropTypes from "prop-types";
import AdConfigTable from "../AdConfig/AdConfigTable";
import ScreenConfigDialog from "./ScreenConfigDialog";
import useFetchScreenConfig from "../../../Controller/ScreenConfig/useFetchScreenConfig";
import ScreenConfigTable from "./ScreenConfigTable";

function ScreenConfigBase(props) {
    ScreenConfigBase.propTypes = {
        appDetails: PropTypes.object.isRequired,
    };

    const [isDialogOpen, setDialogOpen] = useState(false);
    const {loading, data, fetchApi} = useFetchScreenConfig({appId: props.appDetails.id});

    return (
        <Grid container spacing={3} padding={2}>
            <Grid item xs={useWindowSize()[0] < 700 ? 12 : 6}>
                <Typography variant="h5" component="h5" style={{fontWeight: 600}}>
                    Screens Configuration
                </Typography>
            </Grid>
            <Grid item xs={useWindowSize()[0] < 700 ? 12 : 6}>
                <Box display="flex" justifyContent="flex-end">
                    <Button style={{boxShadow: "rgb(120 139 255 / 45%) 5px 5px 10px"}} disableElevation={true}
                            variant="contained" startIcon={<AddRoundedIcon/>} onClick={() => {
                        setDialogOpen(true);
                    }}>
                        Create New Version
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <ScreenConfigTable data={loading ? [] : data} onRefresh={fetchApi}/>
            </Grid>

            <Grid item xs={12}>
                <ScreenConfigDialog appId={props.appDetails.id} isOpen={isDialogOpen} onClose={() => {
                    setDialogOpen(false);
                }} onSave={() => {
                    setDialogOpen(false);
                    fetchApi().then(r => console.log(r));
                }}/>
            </Grid>
        </Grid>
    );
}

export default ScreenConfigBase;