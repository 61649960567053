import React, {useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import {
    Box,
    Button, Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel, ListItem, ListItemText,
    OutlinedInput, Switch,
    TextField
} from "@mui/material";
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';

import Moment from 'moment';

import SyncAltIcon from "@mui/icons-material/SyncAlt";
import useNewApp from "../../../Controller/Apps/newApp";
import useFetchApps from "../../../Controller/Apps/fetchApp";
import LoaderBox from "../../../Components/loadeBox";
import {useSnackbar} from "notistack";


function AppSetting(props) {

    AppSetting.propTypes = {
        appDetails: PropTypes.object.isRequired,
    };


    const [isAvailable, setAvailable] = useState(false);
    const [details, setDetails] = useState(undefined);
    const oneSignal = useRef();
    const packageName = useRef();
    const {loading, setData} = useNewApp();
    const {l, data, fetchApi, getSingle} = useFetchApps();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    useEffect(() => {
        async function fetchMyAPI() {
            let d = (await getSingle({id: props.appDetails.id})).data.app;
            setDetails(d);
            setAvailable(d.isAvailable);
        }
        fetchMyAPI()
    }, []);
    return (
        details === undefined ? <div/> : <Grid container spacing={3} p={1}>
            <Grid item xs={12} marginTop={3}>
                <ListItem style={{width: '100%'}}>
                    <ListItemText id="switch-list-label-wifi"
                                  secondary={Moment(props.appDetails.updated_at).format('d MMM yyy')
                                      + " At " +
                                      Moment(props.appDetails.updated_at).format('hh:MM a')}
                                  primary={props.appDetails.appName}/>
                    <Button variant="outlined" startIcon={<SyncAltIcon/>} onClick={() => {
                        setData({
                            isUpdate: true,
                            data: {
                                id: props.appDetails.id,
                                isAvailable: isAvailable,
                                oneSignal: oneSignal.current.value,
                                packageName: packageName.current.value
                            },
                            onComplete: () => {

                            }
                        })
                    }}>
                        Update
                    </Button>
                </ListItem>

            </Grid>
            <Grid item xs={4}>
                <FormControl sx={{m: 1, width: '100%'}} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Api Key</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={'text'}
                        readOnly
                        defaultValue={details.apiKey}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={()=>{
                                        navigator.clipboard.writeText(details.apiKey);
                                        enqueueSnackbar("Api Key Copied");
                                    }}
                                    edge="end"
                                >
                                    <ContentCopyTwoToneIcon/>
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Api Key"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    inputRef={oneSignal}
                    defaultValue={details.oneSignal}
                    name='onesignal' id="outlined-basic" label="Onesignal Key"
                    variant="outlined" style={{
                    marginTop: 10,
                    width: '100%',
                }}/>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    inputRef={packageName}
                    defaultValue={details.packageName}
                    name='package' id="outlined-basic" label="Package Name"
                    variant="outlined" style={{
                    marginTop: 10,
                    width: '100%'
                }}/>
            </Grid>
            <Grid item xs={4}>
                <ListItem style={{width: '100%'}}>
                    <ListItemText id="switch-list-label-wifi" primary="Maintenance Mode"
                                  secondary={'Pause App Requests'}/>
                    <Switch
                        edge="end"
                        onChange={(event, checked) => setAvailable(checked)}
                        checked={isAvailable}
                        inputProps={{
                            'aria-labelledby': 'switch-list-label-wifi',
                        }}
                    />
                </ListItem>
            </Grid>
            <Grid item xs={12}>
                <Divider style={{marginBottom: 5}}/>

                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <ListItem style={{width: '100%'}}>
                            <ListItemText id="switch-list-label-wifi"
                                          secondary={props.appDetails.appType}
                                          primary={"App Type"}/>
                        </ListItem>
                    </Grid>
                    <Grid item xs={4}>
                        <ListItem style={{width: '100%'}}>
                            <ListItemText id="switch-list-label-wifi"
                                          secondary={Moment(props.appDetails.created_at).format('d MMM yyy')
                                              + " At " +
                                              Moment(props.appDetails.created_at).format('hh:MM a')}
                                          primary={"Create At"}/>
                        </ListItem>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

}

export default AppSetting;