import {useEffect, useState} from "react"
import axios from "axios";
import {baseUrl} from "../Authentication/constansts";
import {useCookies} from "react-cookie";

const useFetchScreenConfig = ({appId}) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [cookies] = useCookies(['session']);
    const fetchApi = async () => {
        try {
            const response = await axios.get(baseUrl + "getCurrentAppScreens/" + appId, {
                headers: {
                    Authorization: 'Bearer ' + cookies.session.token //the token is a variable which holds the token
                }
            });
            let apps = response.data.data;
            setData(apps);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchApi();
    }, []);

    return {loading, data, fetchApi};
};

export default useFetchScreenConfig;