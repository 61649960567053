import React from 'react';
import PropTypes from "prop-types";
import useWindowSize from "../../../Utils/UseWindowSize";
import {Switch} from "@mui/material";
import {DataGrid, GridCellEditStopReasons} from "@mui/x-data-grid";
import useUtilsAdConfig from "../../../Controller/AdConfig/useUtilsAdConfig";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import {useSnackbar} from "notistack";
import useUtilsScreenConfig from "../../../Controller/ScreenConfig/useUtilsScreenConfig";


function ScreenConfigTable(props) {
    ScreenConfigTable.propTypes = {
        data: PropTypes.object.isRequired,
        onRefresh: PropTypes.func.isRequired,
    };
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();


    const {loading, update, deleteConfig, newAppScreen} = useUtilsScreenConfig();
    const columns = [
        {field: 'appVersion', headerName: 'App Version', width: (useWindowSize()[0] * 10) / 100, editable: false},
        {field: 'route', headerName: 'Screen', width: (useWindowSize()[0] * 10) / 100, editable: false},
        {
            field: 'isAdsOn', headerName: 'Is Ads On', width: (useWindowSize()[0] * 10) / 100,
            renderCell: (params) => {
                return <Switch
                    edge="end"
                    onChange={(event, checked) => {
                        update({
                            id: params.id,
                            filedValue: checked,
                            filedName: "isAdsOn",
                            refresh: () => {
                                props.onRefresh();
                            }
                        })
                    }}
                    checked={params.value.isAdsOn}
                    inputProps={{
                        'aria-labelledby': 'switch-list-label-wifi',
                    }}
                />;
            },
        },
        {
            field: 'localClickIndex',
            headerName: 'Local Index',
            width: (useWindowSize()[0] * 20) / 100,
            editable: true,
        },
        {
            field: 'localListCount',
            width: (useWindowSize()[0] * 10) / 100,
            headerName: 'List Count',
            type: 'number',
            editable: true,
        },
        {
            field: 'localGridCount',
            width: (useWindowSize()[0] * 10) / 100,
            headerName: 'Grid Count',
            type: 'number',
            editable: true,
        },

        {
            field: 'isDialogEnable', headerName: 'Dialog', width: (useWindowSize()[0] * 10) / 100,
            renderCell: (params) => {
                return <Switch
                    edge="end"
                    onChange={(event, checked) => {
                        update({
                            id: params.id,
                            filedValue: checked,
                            filedName: "isDialogEnable",
                            refresh: () => {
                                props.onRefresh();
                            }
                        })
                    }}
                    checked={params.value.isDialogEnable}
                    inputProps={{
                        'aria-labelledby': 'switch-list-label-wifi',
                    }}
                />;
            },
        },
        {
            field: 'dialogText',
            width: (useWindowSize()[0] * 15) / 100,
            headerName: 'Dialog Title',
            editable: true,
        },
        {
            field: 'dialogDescription',
            width: (useWindowSize()[0] * 15) / 100,
            headerName: 'Dialog Subtitle',
            editable: true,
        },
        {
            field: 'dialogAction',
            width: (useWindowSize()[0] * 15) / 100,
            headerName: 'Link',
            editable: true,
        },
        {
            field: 'action', headerName: 'Action', width: (useWindowSize()[0] * 15) / 100,
            renderCell: (params) => {
                return <div style={{width: '100%', display: 'flex', justifyContent: 'space-evenly'}}>
                    <Button variant="outlined" startIcon={<DeleteIcon/>} color='error' onClick={() => {
                        deleteConfig({
                                id: params.id,
                                refresh: () => {
                                    props.onRefresh();
                                },
                            }
                        )
                    }
                    }>
                        Delete
                    </Button>
                </div>;
            },
        },

    ];


    return (
        <div style={{height: useWindowSize()[1] * 0.75, width: '100%'}}>
            <DataGrid
                loading={loading}
                rows={
                    props.data.map((e) => {
                        return {
                            id: e.id,
                            appVersion: e.appVersion,
                            route: e.route,
                            isAdsOn: e,
                            localClickIndex: e.localClickIndex.replace("[", "").replace("]", ""),
                            localListCount: e.localListCount,
                            isDialogEnable: e,
                            dialogText: e.dialogText,
                            dialogDescription: e.dialogDescription,
                            localGridCount: e.localGridCount,
                            action: e,
                            dialogAction: e.dialogAction
                        }
                    })
                }
                columns={columns}
                onCellEditCommit={params => {
                    let value = params.value;
                    if (params.field === "localClickIndex") {
                        let temp = [];
                        temp = value.split(',');
                        const isTrue = temp.every(item => item === "0" || item === "1" || item === "2" || item === "3");
                        if (!isTrue) {
                            enqueueSnackbar("Enter Valid Array Value");
                            props.onRefresh();
                            return;
                        }
                        value = temp.toString();
                        value.replace('/\\\\/g', "");
                    }
                    update({
                        id: params.id,
                        filedValue: value,
                        filedName: params.field,
                        refresh: () => {
                            props.onRefresh();
                        }
                    })
                }}
                pageSize={useWindowSize()[1] < 1000 ? 10 : 20}
                rowsPerPageOptions={[5]}
                onCellEditStop={(params, event) => {
                    // console.log(event);
                    // console.log(params);
                    if (params.reason === GridCellEditStopReasons.cellFocusOut) {
                        event.defaultMuiPrevented = true;
                    }
                }}
            />
        </div>
    );
}

export default ScreenConfigTable;