import {useEffect, useState} from "react"
import axios from "axios";
import {baseUrl} from "../Authentication/constansts";
import {useCookies} from "react-cookie";
import {useSnackbar} from "notistack";

const useNewApp = () => {
    const [loading, setLoading] = useState(true)
    const [cookies] = useCookies(['session']);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const setData = async ({isUpdate, data, onComplete}) => {
        try {
            if (!isUpdate) {
                data['userId'] = cookies.session.user.id;
                const response = await axios.post(baseUrl + "createNewApp", data, {
                    headers: {
                        Authorization: 'Bearer ' + cookies.session.token,
                        "Accept": "application/json",
                    }
                });
                if (response.data.status === true) {
                    onComplete();
                }
            } else {
                const response = await axios.post(baseUrl + "updateAppConfig", data, {
                    headers: {
                        Authorization: 'Bearer ' + cookies.session.token,
                        "Accept": "application/json",
                    }
                });
                if (response.data.status === true) {
                    enqueueSnackbar("App Config Updated");
                    onComplete();
                }
            }

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };
    return {loading, setData};
};

export default useNewApp;