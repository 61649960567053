import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Grid, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import useFetchAdIds from "../../../Controller/AppIds/useFetchAdIds";
import LoaderBox from "../../../Components/loadeBox";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import useNewAdIds from "../../../Controller/AppIds/useNewAdIds";
import {useSnackbar} from "notistack";

let mediumGrid = 6;
let smallGrid = 12;
let largeGrid = 3;

function InputComponent({id, title, url, fields, updateUrl}) {

    const {loading, data, fetch} = useFetchAdIds({appId: id, provider: url});
    const updateInfo = useNewAdIds();
    const [sendKeys, setSendKeys] = useState(undefined);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    InputComponent.propTypes = {
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        updateUrl: PropTypes.string.isRequired,
        fields: PropTypes.object.isRequired,
        id: PropTypes.number.isRequired,
    };
    useEffect(() => {
        setSendKeys(
            fields.map((e) => {
                return e.objName;
            })
        );

    }, [data]);
    return (
        <Grid container spacing={3}  >
            <Grid item xs={6} marginTop={3}>
                <Typography variant={'h6'}>
                    {
                        title
                    }
                </Typography>
            </Grid>
            <Grid item xs={6} marginTop={3}>
                <Box display="flex" justifyContent="flex-end">
                    <Button variant="outlined" startIcon={<SyncAltIcon/>} onClick={() => {
                        let obj = {};
                        for (let i = 0; i < fields.length; i++) {
                            let e = fields[i];
                            if (e.ref.current.value === "") {
                                return enqueueSnackbar("Enter " + e.name);
                            }
                            obj[e.objName] = e.ref.current.value;
                        }
                        obj["id"] = data.id;
                        obj["appId"] = id;
                        updateInfo.setData({
                            data: obj,
                            url: updateUrl,
                            onComplete: () => {

                            }
                        })
                    }}>
                        Update
                    </Button>
                </Box>
            </Grid>
            {
                loading ? <div/> :
                    fields.map((e) => {
                        return (
                            <Grid item xs={smallGrid} md={mediumGrid} lg={largeGrid}>
                                <TextField
                                    helperText={"Please enter " + e.name}
                                    defaultValue={data[e.objName]}
                                    inputRef={e.ref} name={e.name} id="outlined-basic" label={e.name}
                                    variant="outlined"

                                    style={{
                                        marginTop: 10,
                                        width: '100%'
                                    }}
                                />
                            </Grid>
                        );
                    })
            }
        </Grid>
    );
}

export default InputComponent;