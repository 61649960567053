import {useState} from "react";
import {useCookies} from "react-cookie";
import axios from "axios";
import {baseUrl} from "../Authentication/constansts";

const useDeleteApp = () => {

    const [load, setLoading] = useState(false)
    const [cookies] = useCookies(['session']);
    const deleteCategory = async ({id, onComplete}) => {
        setLoading(true);
        try {
            const response = await axios.delete(baseUrl + "deleteApp/" + id + "/delete", {
                headers: {
                    Authorization: 'Bearer ' + cookies.session.token //the token is a variable which holds the token
                }
            });
            onComplete();
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    return {load, deleteCategory}

}


export default useDeleteApp;
