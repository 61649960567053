import {useEffect, useState} from "react"
import axios from "axios";
import {baseUrl} from "../Authentication/constansts";
import {useCookies} from "react-cookie";
import {useSnackbar} from "notistack";

const useNewAdIds = () => {
    const [loading, setLoading] = useState(true)
    const [cookies] = useCookies(['session']);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const setData = async ({url, data, onComplete}) => {
        try {
            const response = await axios.post(baseUrl + url, data, {
                headers: {
                    Authorization: 'Bearer ' + cookies.session.token //the token is a variable which holds the token
                }
            });
            if (response.data.status === true) {
                enqueueSnackbar("Ad Ids Updated");
                onComplete();
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(err.data);
        } finally {
            setLoading(false);
        }
    };
    return {loading, setData};
};

export default useNewAdIds;