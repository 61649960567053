import React, {useEffect, useState} from 'react';
import useFetchApps from "../../Controller/Apps/fetchApp";
import MyAppDataTable from "./ManageApp/MyAppDataTable";
import {Box, Button, Grid, Typography} from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CreateNewApp from "./ManageApp/create_new_app";
import useWindowSize from "../../Utils/UseWindowSize";
import AppDetails from "./AppDetails";

function MyApps(props) {
    const {loading, data, fetchApi} = useFetchApps();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleClickOpen = () => {
        setIsDialogOpen(true);
    };

    const handleClose = () => {
        setIsDialogOpen(false);
    };
    return (
        <Grid container spacing={3} padding={2}>
            <Grid item xs={useWindowSize()[0] < 700 ? 12 : 6}>
                <Typography variant="h5" component="h5" style={{fontWeight: 600}}>
                    My Apps
                </Typography>
            </Grid>
            <Grid item xs={useWindowSize()[0] < 700 ? 12 : 6}>
                <Box display="flex" justifyContent="flex-end">
                    <Button style={{boxShadow: "rgb(120 139 255 / 45%) 5px 5px 10px"}} disableElevation={true}
                            variant="contained" startIcon={<AddRoundedIcon/>} onClick={() => handleClickOpen()}>
                        Create App
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <MyAppDataTable data={loading ? [] : data[0]} onRefresh={fetchApi}/>
            </Grid>

            <Grid item xs={12}>
                <CreateNewApp null isOpen={isDialogOpen} onClose={handleClose} onSave={() => {
                    handleClose();
                    fetchApi().then(r => console.log(r));
                }}/>
            </Grid>
        </Grid>
    );


}

export default MyApps;