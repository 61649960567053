import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import EditIcon from '@mui/icons-material/EditRounded';
import useWindowSize from "../../../Utils/UseWindowSize";
import {useEffect, useState} from "react";
import useDeleteApp from "../../../Controller/Apps/deleteApp";
import AppDetails from "../AppDetails";

export default function MyAppDataTable({data, onRefresh}) {
    const [detailDialogState, setDetailDialogState] = useState(false);
    const [selectedRow, setSelectedRow] = useState(undefined);
    const deleteApp = useDeleteApp();

    const columns = [
        {field: 'appName', headerName: 'App Name', width: (useWindowSize()[0] * 30) / 100},
        {field: 'id', type: 'number', headerName: 'App Id', width: (useWindowSize()[0] * 10) / 100},
        {field: 'appType', headerName: 'App Type', width: (useWindowSize()[0] * 10) / 100},
        {field: 'version', type: 'number', headerName: 'App Version', width: (useWindowSize()[0] * 10) / 100},
        {
            field: 'action', headerName: 'Action', width: (useWindowSize()[0] * 20) / 100,
            renderCell: (params) => {
                return <div style={{width: '100%', display: 'flex', justifyContent: 'space-evenly'}}>
                    <Button variant="outlined" startIcon={<EditIcon/>} onClick={() => {
                        setSelectedRow(params.value);
                        setDetailDialogState(true);
                    }}>
                        Edit
                    </Button>

                    <Button variant="outlined" startIcon={<DeleteIcon/>} color='error' onClick={() => {

                        deleteApp.deleteCategory({
                            id: params.value.id, onComplete: () => {
                                onRefresh();
                            }
                        }).then(r => console.log(r));

                    }
                    }>
                        Delete
                    </Button>
                </div>;
            },
        },
    ];

    return (
        <div style={{height: useWindowSize()[1] * 0.85, width: '100%'}}>
            <DataGrid
                rows={
                    data.map((value) => {
                        return {
                            appName: value.appName,
                            id: value.id,
                            appType: value.appType,
                            version: value.version,
                            action: value
                        }
                    })
                }
                columns={columns}
                pageSize={useWindowSize()[1] < 1000 ? 10 : 20}
                rowsPerPageOptions={[5]}
            />
            {
                !detailDialogState ? null :
                    <AppDetails open={detailDialogState} onClose={() => setDetailDialogState(false)}
                                appDetails={selectedRow}/>

            }
        </div>
    );
}
