import React, {useEffect, useRef} from 'react';
import PropTypes from "prop-types";
import {Divider, Grid} from "@mui/material";
import InputComponent from "./InputComponent";
import Box from "@mui/material/Box";
function AdIdsComponent(props) {
    AdIdsComponent.propTypes = {
        appDetails: PropTypes.object.isRequired,
    };

    useEffect(() => {
    }, []);
    return (
        <Box padding={2}>
            <InputComponent
                id={props.appDetails.id}
                updateUrl={'updateGoogleAds'}
                url={'googleIds'}
                fields={[
                    {
                        name: "Banner Id",
                        objName: "bannerId",
                        ref: useRef('bannerId'),
                    },
                    {
                        name: "FullScreen Id",
                        objName: "fullScreenId",
                        ref: useRef('fullScreenId'),
                    },
                    {
                        name: "AppOpen Id",
                        objName: "appOpenId",
                        ref: useRef('appOpenId'),
                    },
                    {
                        name: "Reward Id",
                        objName: "rewardId",
                        ref: useRef('rewardId'),
                    },
                    {
                        name: "Native Id",
                        objName: "nativeId",
                        ref: useRef('nativeId'),
                    }
                ]}
                title={'Google Ad Ids'}
            />
            <InputComponent
                id={props.appDetails.id}
                updateUrl={'updateUnityAds'}
                url={'unityIds'}
                fields={[
                    {
                        name: "UnityApp Id",
                        objName: "gameId",
                        ref: useRef('gameId'),
                    },
                    {
                        name: "Reward Id",
                        objName: "rewardPlacement",
                        ref: useRef('rewardPlacement'),
                    },
                    {
                        name: "FullScreen Id",
                        objName: "fullScreenPlacement",
                        ref: useRef('fullScreenPlacement'),
                    }
                ]}
                title={'Unity Ad Ids'}
            />
            <InputComponent
                id={props.appDetails.id}
                updateUrl={'updateFacebookAds'}
                url={'facebookIds'}
                fields={[
                    {
                        name: "Banner Id",
                        objName: "bannerId",
                        ref: useRef('bannerId'),
                    },
                    {
                        name: "FullScreen Id",
                        objName: "fullScreenId",
                        ref: useRef('fullScreenId'),
                    },
                    {
                        name: "Reward Id",
                        objName: "rewardId",
                        ref: useRef('rewardId'),
                    },
                    {
                        name: "Native Id",
                        objName: "nativeId",
                        ref: useRef('nativeId'),
                    },
                ]}
                title={'Facebook Ad Ids'}
            />

            <InputComponent
                id={props.appDetails.id}
                updateUrl={'updateIronSourceAds'}
                url={'ironSourceIds'}
                fields={[
                    {
                        name: "IronSourceApp Id",
                        objName: "ironSourceAppId",
                        ref: useRef('ironSourceAppId'),
                    }
                ]}
                title={'IronSource Ad Ids'}
            />

        </Box>
    );
}

export default AdIdsComponent;