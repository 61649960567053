import './App.css';
import {ThemeProvider} from "@mui/material/styles";
import {SnackbarProvider} from "notistack";
import theme from "./theme/theme";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./Screens/LoginScreen";
import ProtectedRoute from "./routes/ProtectedRoute";
import HomScreen from "./Screens/HomePage/HomePage";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                maxSnack={1}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/*"
                            element={
                                <ProtectedRoute>
                                    <HomScreen/>
                                </ProtectedRoute>
                            }
                        />
                        <Route path="login" element={<Login/>}/>
                    </Routes>
                </BrowserRouter>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
