import React, {useEffect, useRef} from 'react';
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    ListItem, ListItemText, Switch,
    TextField
} from "@mui/material";
import UseUtilsAdConfig from "../../../Controller/ScreenConfig/useUtilsScreenConfig";
import {useSnackbar} from "notistack";

function ScreenConfigDialog(props) {
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    ScreenConfigDialog.propTypes = {
        data: PropTypes.object.isRequired,
        isOpen: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        appId: PropTypes.object.isRequired,
    };

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    // Components

    const [isAdsOn, setAdsConfig] = React.useState(true);
    const [isDialogOn, setDialogConfig] = React.useState(false);

    const routeName = useRef();
    const appVersion = useRef();
    const localIndex = useRef();
    const listIndex = useRef();
    const gridIndex = useRef();
    const dialogText = useRef('s');
    const dialogDescription = useRef();
    const dialogLink = useRef();

    const {loading, update, deleteConfig, newAppScreen} = UseUtilsAdConfig();
    useEffect(() => {
    }, []);

    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.isOpen}
            onClose={props.onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {"New Screen Configuration"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <ListItem style={{width: '100%'}}>
                                <ListItemText id="switch-list-label-wifi" primary="Is Ads On"/>
                                <Switch
                                    edge="end"
                                    onChange={(event, checked) => setAdsConfig(checked)}
                                    checked={isAdsOn}
                                    inputProps={{
                                        'aria-labelledby': 'switch-list-label-wifi',
                                    }}
                                />
                            </ListItem>
                        </Grid>
                        <Grid item xs={6}>
                            <ListItem style={{width: '100%'}}>
                                <ListItemText id="switch-list-label-wifi" primary="Show Dialog"/>
                                <Switch
                                    edge="end"
                                    onChange={(event, checked) => setDialogConfig(checked)}
                                    checked={isDialogOn}
                                    inputProps={{
                                        'aria-labelledby': 'switch-list-label-wifi',
                                    }}
                                />
                            </ListItem>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                inputRef={routeName}
                                helperText={"Please enter Screen Route"}
                                name={"route"} id="route" label={"Route Name"}
                                variant="outlined"
                                style={{
                                    marginTop: 10,
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                inputRef={appVersion}
                                type={'number'}
                                helperText={"Please enter App Version"}
                                name={"version"} id="version" label={"App Version"}
                                variant="outlined"
                                style={{
                                    marginTop: 10,
                                    width: '100%'
                                }}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        inputRef={localIndex}
                        helperText={"Please enter Local Click Index"}
                        name={"index"} id="localIndex" label={"Local Index"}
                        variant="outlined"
                        style={{
                            marginTop: 10,
                            width: '100%'
                        }}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                inputRef={listIndex}
                                helperText={"Please enter List Index"}
                                name={"index"} id="listIndex" label={"List Index"}
                                variant="outlined"
                                type={'number'}
                                style={{
                                    marginTop: 10,
                                    width: '100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                inputRef={gridIndex}
                                helperText={"Please enter Grid Index"}
                                name={"index"} id="gridIndex" label={"Grid Index"}
                                variant="outlined"
                                type={'number'}
                                style={{
                                    marginTop: 10,
                                    width: '100%'
                                }}
                            />
                        </Grid>
                    </Grid>
                    {
                        isDialogOn ?                 <div>
                            <TextField
                                inputRef={dialogText}
                                helperText={"Please enter Dialog Title"}
                                name={"title"} id="title" label={"Dialog Title"}
                                variant="outlined"
                                style={{
                                    marginTop: 10,
                                    width: '100%'
                                }}
                            />
                            <TextField
                                inputRef={dialogDescription}
                                helperText={"Please enter Dialog Subtitle"}
                                name={"subtitle"} id="subtitle" label={"Dialog Subtitle"}
                                variant="outlined"
                                style={{
                                    marginTop: 10,
                                    width: '100%'
                                }}
                            />
                            <TextField
                                inputRef={dialogLink}
                                helperText={"Please enter Dialog Link"}
                                name={"link"} id="link" label={"Dialog Link"}
                                variant="outlined"
                                style={{
                                    marginTop: 10,
                                    width: '100%'
                                }}
                            />
                        </div>
                       :<div/>
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.onClose} color={'error'}>
                    close
                </Button>
                <Button onClick={() => {
                    let l = localIndex.current.value;
                    let temp = [];
                    temp = l.split(',');
                    const isTrue = temp.every(item => item === "0" || item === "1" || item === "2" || item === "3");
                    if (!isTrue) {
                        enqueueSnackbar("Enter Valid Array Value");
                        return;
                    }
                    l = temp.toString();
                    l.replace('/\\\\/g', "");
                    newAppScreen(
                        {
                            onRefresh: () => {
                                props.onSave();
                            },
                            params: {
                                route: routeName.current.value,
                                isAdsOn: isAdsOn,
                                appVersion: appVersion.current.value,
                                appId: props.appId,
                                localClickIndex: l,
                                localListCount: listIndex.current.value,
                                localGridCount: gridIndex.current.value,
                                isDialogEnable: isDialogOn,
                                dialogText: dialogText.current.value,
                                dialogDescription: dialogDescription.current.value,
                                dialogAction: dialogLink.current.value,
                            }
                        }
                    );
                }} autoFocus>
                    save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ScreenConfigDialog;