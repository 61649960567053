import {useEffect, useState} from "react"
import axios from "axios";
import {baseUrl} from "../Authentication/constansts";
import {useCookies} from "react-cookie";

const useFetchAdConfig = ({appId}) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [cookies] = useCookies(['session']);
    const fetchApi = async () => {
        try {
            const response = await axios.get(baseUrl + "getCurrentVersions/" + appId, {
                headers: {
                    Authorization: 'Bearer ' + cookies.session.token //the token is a variable which holds the token
                }
            });
            let apps = response.data.data;
            setData(apps);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };


    const newAdConfig = async ({appId}) => {
        try {
            console.log(data.length === 0 ? 1 :data[0].appVersion);
            setLoading(true);
            let requestData = {};
            requestData['appId'] = appId;
            requestData['appVersion'] = data.length === 0 ? 1 : parseInt(data[0].appVersion) + 1;
            requestData['userId'] = cookies.session.user.id;
            const response = await axios.post(baseUrl + "createNewVersion", requestData, {
                headers: {
                    Authorization: 'Bearer ' + cookies.session.token //the token is a variable which holds the token
                }
            });
            fetchApi();
        } catch (err) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchApi();
    }, []);

    return {loading, data, fetchApi, newAdConfig};
};

export default useFetchAdConfig;