import React, {useEffect, useRef} from 'react';
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, Grid, InputLabel,
    ListItem, ListItemText, MenuItem, Select, Switch,
    TextField
} from "@mui/material";
import UseUtilsAdConfig from "../../../Controller/ScreenConfig/useUtilsScreenConfig";
import {useSnackbar} from "notistack";
import useAssetsUtils from "../../../Controller/AppAssets/useAssetsUtils";

function AppAssetsDialog(props) {
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    AppAssetsDialog.propTypes = {
        data: PropTypes.object.isRequired,
        isOpen: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        appId: PropTypes.object.isRequired,
    };

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    // Components

    const [selectedType, setType] = React.useState("Image");
    const {loading, update, deleteConfig, newAppScreen} = useAssetsUtils();

    const link = useRef();


    useEffect(() => {
    }, []);

    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.isOpen}
            onClose={props.onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {"New Asset"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>

                    <FormControl fullWidth>
                        <InputLabel id="selectedCategoryLabel" style={{marginTop: 20}}>App Platform</InputLabel>
                        <Select
                            labelId="select Assets Type"
                            id="assets Type"
                            value={selectedType}
                            style={{
                                marginBottom: 20,
                                marginTop: 20,
                                width: "100%"
                            }}
                            label="Select Assets Type"
                            onChange={(event) => {
                                setType(event.target.value);
                            }}
                        >
                            {
                                ["Image", "Json"].map((item) => {
                                    return <MenuItem style={{width: "100%"}} key={item}
                                                     value={item}>{item}</MenuItem>;
                                })
                            }
                        </Select>
                    </FormControl>

                    <TextField
                        inputRef={link} name='link' id="outlined-basic" label="Asset Link"
                        variant="outlined" style={{
                        marginTop: 10,
                        width: '100%'
                    }}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.onClose} color={'error'}>
                    close
                </Button>
                <Button onClick={() => {
                    newAppScreen(
                        {
                            params: {
                                "appId": props.appId,
                                "appVersion": 1,
                                "type": selectedType,
                                "link": link.current.value
                            },
                            onRefresh: () => {
                                enqueueSnackbar("Assets Created");
                                props.onSave();
                            }
                        }
                    )
                }} autoFocus>
                    save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AppAssetsDialog;