import React, {useState} from 'react';
import PropTypes from "prop-types";
import {Box, Button, Grid, Typography} from "@mui/material";
import useWindowSize from "../../../Utils/UseWindowSize";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AppAssetsDialog from "./AppAssetsDialog";
import useFetchAssets from "../../../Controller/AppAssets/useFetchAssets";
import AppAssetsTable from "./AppAssetsTable";

function AppAssetBase(props) {
    AppAssetBase.propTypes = {
        appDetails: PropTypes.object.isRequired,
    };
    const [isDialogOpen, setDialogOpen] = useState(false);
    const {loading, data, fetchApi} = useFetchAssets({appId: props.appDetails.id});

    return (
        <Grid container spacing={3} padding={2}>
            <Grid item xs={useWindowSize()[0] < 700 ? 12 : 6}>
                <Typography variant="h5" component="h5" style={{fontWeight: 600}}>
                    App Assets
                </Typography>
            </Grid>
            <Grid item xs={useWindowSize()[0] < 700 ? 12 : 6}>
                <Box display="flex" justifyContent="flex-end">
                    <Button style={{boxShadow: "rgb(120 139 255 / 45%) 5px 5px 10px"}} disableElevation={true}
                            variant="contained" startIcon={<AddRoundedIcon/>} onClick={() => {
                        setDialogOpen(true);
                    }}>
                        Create New Asset
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <AppAssetsTable data={loading ? [] : data} onRefresh={fetchApi}/>
            </Grid>

            <Grid item xs={12}>
                <AppAssetsDialog appId={props.appDetails.id} isOpen={isDialogOpen} onClose={() => {
                    setDialogOpen(false);
                }} onSave={() => {
                    setDialogOpen(false);
                    fetchApi().then(r => console.log(r));
                }}/>
            </Grid>
        </Grid>
    );
}

export default AppAssetBase;