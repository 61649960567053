import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import {Box, Divider, IconButton, Tab, Tabs, Typography} from "@mui/material";
import PropTypes from "prop-types";
import AdIdsComponent from "./AdIds/AdIdsComponent";
import AdConfigBase from "./AdConfig/AdConfigBase";
import ScreenConfigBase from "./ScreenConfig/ScreenConfigBase";
import AppSetting from "./AppSettings/AppSetting";
import AppAssetBase from "./AppAssets/AppAssetBase";

function AppDetails(props) {
    const theme = useTheme();
    const [selectedTab, setSelectedTab] = useState(0);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    AppDetails.propTypes = {
        open: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        appDetails: PropTypes.object.isRequired,
    };
    const onTabChange = (event, newValue) => {

        setSelectedTab(newValue);
    };

    const onDialogClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        props.onClose();
    };
    useEffect(() => {
    }, []);
    let tabChildren = [
        <AdIdsComponent appDetails={props.appDetails}/>,
        <AdConfigBase appDetails={props.appDetails}/>,
        <ScreenConfigBase appDetails={props.appDetails}/>,
        <AppAssetBase appDetails={props.appDetails} />,
        <AppSetting appDetails={props.appDetails}/>,
    ];


    return (
        <Dialog
            fullScreen={true}
            open={props.open}
            onClose={onDialogClose}

            unselectable={'off'}
            aria-labelledby="responsive-dialog-title"
        >

            <DialogTitle id="responsive-dialog-title">
                {"Edit App"}

                <IconButton
                    aria-label="close"
                    onClick={props.onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>


            </DialogTitle>
            <Tabs selectionFollowsFocus value={selectedTab} tabIndex={0} onChange={onTabChange}
                  aria-label="basic tabs example">
                <Tab label="Ad Ids" value={0}/>
                <Tab label="Ad Config" value={1}/>
                <Tab label="Screens" value={2}/>
                <Tab label="Assets" value={3}/>
                <Tab label="App Config" value={4}/>
            </Tabs>
            <Divider/>

            <DialogContent style={{padding: 0}}>
                {
                    tabChildren[selectedTab]
                }
            </DialogContent>
        </Dialog>
    );
}

export default AppDetails;