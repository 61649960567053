import {useEffect, useState} from "react"
import axios from "axios";
import {baseImageUrl, baseUrl} from "../Authentication/constansts";
import {useCookies} from "react-cookie";

const useFetchApps = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [cookies, setCookie, removeCookie] = useCookies(['session']);
    const fetchApi = async () => {
        try {
            const response = await axios.get(baseUrl + "getApps", {
                headers: {
                    Authorization: 'Bearer ' + cookies.session.token,
                    "Accept": "application/json",
                }
            });
            let apps = response.data.apps;
            setData([apps]);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    const getSingle = async ({id}) => {
        return await axios.get(baseUrl + "getApp/" + id, {
            headers: {
                Authorization: 'Bearer ' + cookies.session.token,
                "Accept": "application/json",
            }
        });
    };

    useEffect(() => {
        fetchApi().then(r => console.log("Fetched Apps"));
    }, []);

    return {loading, data, fetchApi, getSingle};
};

export default useFetchApps;