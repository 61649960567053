import React, {useState} from 'react';
import {useCookies} from "react-cookie";
import axios from "axios";
import {baseUrl} from "../Authentication/constansts";
import {useSnackbar} from "notistack";

function UseUtilsAdConfig() {
    const [loading, setLoading] = useState(false);
    const [cookies] = useCookies(['session']);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const update = async ({id, filedName, filedValue, refresh}) => {
        try {
            setLoading(true);
            let requestData = {};
            requestData['id'] = id;
            requestData[filedName] = filedValue;
            const response = await axios.post(baseUrl + "updateAppScreen", requestData, {
                headers: {
                    Authorization: 'Bearer ' + cookies.session.token,//the token is a variable which holds the token,
                    "Accept": "application/json",
                }
            });
            setLoading(false);
            refresh();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };
    const deleteConfig = async ({id, refresh}) => {
        try {
            setLoading(true);
            const response = await axios.delete(baseUrl + "deleteAppScreen/" + id + "/delete", {
                headers: {
                    Authorization: 'Bearer ' + cookies.session.token,//the token is a variable which holds the token,
                    "Accept": "application/json",
                }
            });
            setLoading(false);
            refresh();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };
    const newAppScreen = async ({params, onRefresh}) => {
        try {
            setLoading(true);
            params['userId'] = cookies.session.user.id;
            console.log(params);
            axios.post(baseUrl + "createNewAppScreen", params, {
                headers: {
                    Authorization: 'Bearer ' + cookies.session.token, //the token is a variable which holds the token
                    "Accept": "application/json"
                }
            }).catch(reason =>{
                if (reason.response.status === 500) {
                    enqueueSnackbar(reason.response.data.msg);
                    return;
                }
                console.log(reason);
                setLoading(false);
            });
            onRefresh();
        } catch (err) {

            setLoading(false);
        }
    };

    return {loading, update, deleteConfig, newAppScreen};
}

export default UseUtilsAdConfig;