import React, {useEffect} from 'react';
import {Box, Button, Grid, Typography} from "@mui/material";
import useWindowSize from "../../../Utils/UseWindowSize";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import PropTypes from "prop-types";
import useFetchAdConfig from "../../../Controller/AdConfig/useFetchAdConfig";
import AdConfigTable from "./AdConfigTable";

function AdConfigBase(props) {
    AdConfigBase.propTypes = {
        appDetails: PropTypes.object.isRequired,
    };

    const {loading, data, fetchApi, newAdConfig} = useFetchAdConfig({appId: props.appDetails.id});

    return (
        <Grid container spacing={3} padding={2}>
            <Grid item xs={useWindowSize()[0] < 700 ? 12 : 6}>
                <Typography variant="h5" component="h5" style={{fontWeight: 600}}>
                    Ads Configs
                </Typography>
            </Grid>
            <Grid item xs={useWindowSize()[0] < 700 ? 12 : 6}>
                <Box display="flex" justifyContent="flex-end">
                    <Button style={{boxShadow: "rgb(120 139 255 / 45%) 5px 5px 10px"}} disableElevation={true}
                            variant="contained" startIcon={<AddRoundedIcon/>} onClick={() => {
                        if (!loading) {
                            setTimeout(() => {
                                newAdConfig({appId: props.appDetails.id});
                            }, 300)
                        }
                    }}>
                        Create New Version
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <AdConfigTable data={loading ? [] : data} onRefresh={fetchApi}/>
            </Grid>

            <Grid item xs={12}>

            </Grid>
        </Grid>
    );
}

export default AdConfigBase;