import {baseUrl} from './constansts';

const axios = require('axios');

export async function loginUser(data) {
    const response = await axios.post(baseUrl + `loginUser`, data, {
        headers: {
            "Accept": "application/json"
        }
    });
    return response.data;
}