import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {OutlinedInput, FormControl, InputLabel, InputAdornment, IconButton, CircularProgress} from "@mui/material"
import Container from '@mui/material/Container';
import {ThemeProvider} from '@mui/material/styles';
import {LockOutlined, Visibility, VisibilityOff} from '@mui/icons-material';
import {loginUser} from '../Controller/Authentication/Auth'
import Snackbar from '@mui/material/Snackbar';
import {useNavigate} from "react-router-dom";
import {useCookies} from 'react-cookie';
import LoaderBox from '../Components/loadeBox';
import useNotification from '../Components/useNotification';
import theme from "../theme/theme";
import {useSnackbar} from "notistack";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                CoderRB
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


export default function Login() {
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState("");
    let navigate = useNavigate();


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const [cookies, setCookie, removeCookie] = useCookies(['session']);
    const [getVisible, setVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [msg, sendNotification] = useNotification();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoading(true);
        loginUser({
            email: data.get('email'),
            password: data.get('password'),
        }).then((res) => {
            if (res.status === true) {
                setLoading(false);
                setCookie('session', {
                    isLogin: true,
                    token: res.token,
                    user: res.user
                })
                sendNotification({msg: res.msg, variant: 'success'});
                navigate("/", {replace: true});
            } else {
                setLoading(false);
                enqueueSnackbar(res.msg);
            }
        }).catch((error) => {
            setLoading(false);
            if (error.response.status === 422) {
                enqueueSnackbar(error.response.data.message);
                return;
            }
            enqueueSnackbar(error.response.data.msg);
            console.log(error);
        });

    };


    React.useEffect(() => {
        if (cookies.session !== undefined) {
            return navigate("/", {replace: true});
        }
    }, [cookies]);

    return (
        loading ? <LoaderBox/> :
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}

                    >
                        <Avatar sx={{m: 1, bgcolor: '#788bff'}}>
                            <LockOutlined/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}} width={400}>
                            <TextField
                                color="primary"
                                margin="normal"
                                required
                                fullWidth
                                variant='outlined'
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus

                            />

                            <Box
                                minHeight={25}></Box>

                            <FormControl variant="filled" fullWidth>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <OutlinedInput
                                    id="password"
                                    name="password"
                                    aria-label='password'
                                    type={getVisible ? 'text' : 'password'}
                                    // value={values.password}
                                    // onChange={handleChange('password')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => {
                                                    setVisible(!getVisible);
                                                }}
                                                // onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {getVisible ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <Box
                                    minHeight={25}></Box>
                            </FormControl>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disableElevation={true}
                                sx={{mt: 3, mb: 2}}
                                style={{boxShadow: "rgb(120 139 255 / 45%) 5px 5px 10px"}}
                            >
                                Sign In
                            </Button>

                        </Box>
                    </Box>
                    <Copyright sx={{mt: 8, mb: 4}}/>
                </Container>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={error}
                />
            </ThemeProvider>
    );
}