import React from 'react';
import NavBar from "../../Components/NavBar";


export default function HomScreen() {

    return (
        <NavBar/>
    );
}
