import  React from 'react';

import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";
const ProtectedRoute = ({ isLoggedIn, children }) => {
    const [cookies, setCookie, removeCookie] = useCookies(['session']);
    if(cookies.session === undefined){
        return <Navigate to="/login" replace />;
    }

    if (cookies.session.isLogin === undefined) {
        return <Navigate to="/login" replace />;
    }
   
    return children;
};
export default ProtectedRoute;