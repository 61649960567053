import React, {useState} from 'react';
import {useCookies} from "react-cookie";
import axios from "axios";
import {baseUrl} from "../Authentication/constansts";

function UseUtilsAdConfig() {
    const [loading, setLoading] = useState(false);
    const [cookies] = useCookies(['session']);
    const update = async ({id, filedName, filedValue, refresh}) => {
        try {
            setLoading(true);
            let requestData = {};
            requestData['id'] = id;
            requestData[filedName] = filedValue;
            const response = await axios.post(baseUrl + "updateAdsConfig", requestData, {
                headers: {
                    Authorization: 'Bearer ' + cookies.session.token,//the token is a variable which holds the token,
                    "Accept": "application/json",
                }
            });
            setLoading(false);
            refresh();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };
    const deleteConfig = async ({id, refresh}) => {
        try {
            setLoading(true);
            const response = await axios.delete(baseUrl + "deleteAdsConfig/" + id + "/delete", {
                headers: {
                    Authorization: 'Bearer ' + cookies.session.token,//the token is a variable which holds the token,
                    "Accept": "application/json",
                }
            });
            setLoading(false);
            refresh();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };
    return {loading, update, deleteConfig};
}

export default UseUtilsAdConfig;