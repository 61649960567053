import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, {useEffect, useRef} from "react";
import {useTheme} from '@mui/material/styles';
import {
    Box, InputLabel, MenuItem, Select,
    TextField,
} from "@mui/material";
import useNewApp from "../../../Controller/Apps/newApp";
import useWindowSize from "../../../Utils/UseWindowSize";

export default function CreateNewApp({isOpen, onClose, onSave}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const {isLoading, setData} = useNewApp();
    const [isUpdate, setIsUpdate] = React.useState(false);
    const [selectedPlatform, setSelectedPlatform] = React.useState(undefined);

    const textFieldRed = useRef('categoryName')


    useEffect(() => {

    }, [])


    return (
        <Box component="form" noValidate sx={{mt: 1}} width={useWindowSize()[0] / 2}>
            <Dialog
                fullScreen={fullScreen}
                open={isOpen}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {isUpdate ? "Update App Config" : "New App"}
                </DialogTitle>
                <DialogContent>
                    <TextField

                        inputRef={textFieldRed} name='name' id="outlined-basic" label="App Name"
                        variant="outlined" style={{
                        marginTop: 10,
                        width: fullScreen ? '100%' : 400
                    }}/>
                    <InputLabel id="selectedCategoryLabel" style={{marginTop: 20}}>App Platform</InputLabel>
                    <Select
                        labelId="selectedCategoryLabel"
                        id="selectedCategory"
                        value={selectedPlatform}
                        style={{
                            marginBottom: 20,
                            marginTop: 20,
                            width: "100%"
                        }}
                        label="App Platform"
                        onChange={(event) => {
                            setSelectedPlatform(event.target.value);
                        }}
                    >
                        {
                            ["Android", "IOS"].map((item) => {
                                return <MenuItem style={{width: "100%"}} key={item}
                                                 value={item}>{item}</MenuItem>;
                            })
                        }
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        onClose();
                        setSelectedPlatform(undefined);
                    }} color='error'>
                        Close
                    </Button>
                    <Button type="submit" onClick={
                        async () => {
                            await setData({
                                isUpdate: false,
                                data:
                                    {
                                        appName: textFieldRed.current.value,
                                        appType: selectedPlatform,

                                    },
                                onComplete: () => {
                                    setSelectedPlatform(undefined);
                                    onSave();
                                }
                            });
                        }
                    }>
                        {isUpdate ? "Update" : "Save"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}